
import { defineComponent } from 'vue'
import { getModule } from 'vuex-module-decorators';
import { ISignageList, ISignageItem, ISignageItemReorderViewModel, IMessageSignageUpdate, IMessageSignageListUpdate } from '../../store/interfaces/ISignage';
import SignageState, { ISignageItemStateObject, ISignageState, IDeleteSignageItemPayload, IScheduleSignageItemPayload, IReorderSignagePayload } from '../../store/modules/signage';
import SignageListState, { ISignageListState, IScheduleSignageListPayload } from '../../store/modules/signageList';
import Icon from "../../components/Icon.vue"
import InlineTextLoader from '../../components/InlineTextLoader.vue';
import draggable from 'vuedraggable'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import deepEqual from 'deep-equal';
import { ElMessage } from 'element-plus';
import ModelSelector from '../ModelSelector.vue';
import SignageListSelectionModal from '../../components/SignageListSelectionModal.vue';
import { mdiFullscreen, mdiAlertCircle, mdiInformationOutline, mdiChevronRight, mdiDotsVertical, mdiPencil, mdiDelete, mdiCalendarClock, mdiClockTimeFive,
mdiDragHorizontalVariant, mdiMagnify, mdiFormatListText, mdiCrosshairsGps,
mdiInformationVariant, mdiVideoImage, mdiBookOpen, mdiSpotlight, mdiMap, mdiWeb, mdiWeatherPartlyCloudy, mdiViewCarousel, mdiViewSplitVertical, mdiGridLarge,
mdiTextBoxPlus, mdiStarBox, mdiFaceMask, mdiHandWash, mdiAccountGroup, mdiDraw, mdiPresentation, mdiFolderAlert,
mdiSocialDistance6Feet, mdiOpenInNew, mdiWeatherCloudyClock, mdiHandWater, mdiEyeOff, mdiEye, mdiCursorMove, mdiCardMultiple, mdiExclamationThick, mdiCloseCircleOutline, mdiPencilOutline } from '@mdi/js';
import { ISqStoreActionResponse } from '../../store/interfaces/ISqStoreActionResponse';
import PageDirtyService from '../../services/PageDirtyService';
import moment from 'moment';
import OrganizationState, { IOrganizationState } from '../../store/modules/organizations';
import RoleState, {IRoleState} from '../../store/modules/role';
import RoomState, { IRoomState } from '../../store/modules/rooms';
import { IRoom, IRoomDropDownList } from '../../store/interfaces/IRoom';
import { IModelSelectionPool } from '../../store/interfaces/IModelSelectionPool';
import { IOrganization } from '../../store/interfaces/IOrganization';
import UserState, { IUserState } from '../../store/modules/users';
import { v4 as uuidv4 } from 'uuid';
import TierState, { ITierState } from '../../store/modules/tier';
import TierLimit from '../../components/TierLimit.vue';
import DigitalAssetUploadService from '../../services/DigitalAssetUploadService';

let signageItemState = {} as ISignageState,
    signageListState = {} as ISignageListState,
    organizationState = {} as IOrganizationState,
    roomState = {} as IRoomState,
    roleState = {} as IRoleState,
    userState = {} as IUserState,
    tierState = {} as ITierState;

export interface IScheduleProperties {
    isDayEnabled: boolean;
    isDateEnabled: boolean;
    isTimeEnabled: boolean;
    scheduledTimeSet: boolean;
    activeDaysOfWeek: string[];
    startTime: null | Date;
    endTime: null | Date;
    startDate: null | Date;
    endDate: null | Date;
}

interface IFileDisplay {
    id: string;
    uploadFile: File;
    img: any;
    preview: any;
    isVideo: boolean;
    isValid: boolean;
    playSound: boolean;
    processedSucessfully: boolean;
    uploadFailed?: boolean;
    invalidReason?: string;
}

export default defineComponent({
    name: 'SignageListDetails',
    data() {
        return {
            mutatableSignageItemsStateObject: {} as ISignageItemStateObject,
            isSignageListItemsLoading: false as boolean,
            signageList: {} as ISignageList,
            organization: {} as IOrganization,
            selectedLayoutType: {} as any,
            layoutTypeChoiceVisibile: false as boolean,
            isEditingListName: false as boolean,
            selectedLayoutTypeTab: 'general' as string,
            openSignageItemContextId: null as string | null,
            signageItemNameBeingEditedId: null as string | null,
            isSaving: false as boolean,
            dataReloadInterval: null as any | null,
            isDraggingItems: false as boolean,
            signageItemDeletionId: '' as string,
            uploadLimit: 100,
            reloadDebounce: null as any,
            messageDebounce: null as any,
            dragOptions: {
                animation: 150,
            },
            allRooms: [] as IRoomDropDownList[],
            isMoreActionsPopoverOpen: false as boolean,
            moveListVisible: false,
            poppedUpList: {} as ISignageList,
            selectedSignages: [] as ISignageItem[],
            signagesToMove: [] as ISignageItem[],
            signagesToDuplicate: [] as ISignageItem[],
            signagesToDelete: [] as ISignageItem[],
            signagesToSchedule: [] as ISignageItem[],
            allSignageLists: [] as ISignageList[],
            activeTab: 'overview',
            daysOfWeek: [
                    {
                        order:1,
                        name: 'Mon',
                        value: 'Monday'
                    },
                    {   
                        order:2,
                        name: 'Tues',
                        value: 'Tuesday'
                    },
                    {
                        order:3,
                        name: 'Wed',
                        value: 'Wednesday'
                    },
                    {
                        order:4,
                        name: 'Thurs',
                        value: 'Thursday'
                    },
                    {
                        order:5,
                        name: 'Fri',
                        value: 'Friday'
                    },
                    {
                        order:6,
                        name: 'Sat',
                        value: 'Saturday'
                    },
                    {
                        order:7,
                        name: 'Sun',
                        value: 'Sunday'
                    },
                ] as any,
            
            scheduleFormRules: {
                date: [{ validator: this.validateDate, trigger: 'blur' }],
                time: [{ validator: this.validateTime, trigger: 'blur' }],
                day: [{ validator: this.validateDay, trigger: 'blur' }],
                scheduled: [{ validator: this.validateScheduled, trigger: 'blur' }]
            } as any,
            overviewScheduleFormRules: {
                isDateEnabled: [{ validator: this.overviewValidateDate, trigger: 'blur' }],
                isTimeEnabled: [{ validator: this.overviewValidateTime, trigger: 'blur' }],
                isDayEnabled: [{ validator: this.overviewValidateDay, trigger: 'blur' }],
                scheduledTimeSet: [{ validator: this.overviewValidateScheduled, trigger: 'blur' }],
                name:[{ required: true, validator: this.overviewSignageListName, trigger: 'change'}]
            } as any,
            
            isRescheduling: false as boolean,
            isScheduleSaveWarningVisible: false as boolean,
            isSignageDeleteLoading: false as boolean,
            isDeleteModalVisible: false as boolean,
            signageItemToShowHide:null as ISignageItem | null,
            isShowHideLoading: false as boolean,
            isSetRoomsLoading: false as boolean,
            isSignageListsMoveLoading: false as boolean,
            isSchedulingSingleItem: false as boolean,
            isSignageScheduleLoading: false as boolean,
            isDurationScheduleLoading: false as boolean,
            isSignageQuotaLoading: false as boolean,
            selectedSignageItemsNameCount: 5,
            signagesToShowHide: [] as ISignageItem[],
            isSingleSignagePoppedUp: false as boolean,
            showNoAccessRoomMessage: false as boolean,
            massScheduleSettings: {
                isDayEnabled: false as boolean,
                isDateEnabled: false as boolean,
                isTimeEnabled: false as boolean,
                scheduledTimeSet: false as boolean,
                activeDaysOfWeek: [] as string[],
                startTime: null,
                endTime: null,
                dates: [] as Date[],
            } as any, 
            massDurationSettings: {
                showDuration: false as boolean,
                showLoop: false as boolean,
                playUntilEnd: false as boolean,
                durationToDisplay: 5 as any,
                playUntilEndLoopCount: 1 as number
            },
            signagesPoppedUp: [] as ISignageItem[],

            schedulingSettings: {
                isDayEnabled: false as boolean,
                isDateEnabled: false as boolean,
                isTimeEnabled: false as boolean,
                date: new Date(),
                time: new Date(),
                days: [] as string[],


                scheduledTimeSet: false as boolean,
                activeDaysOfWeek: [] as string[],
                startTime: new Date(),
                endTime: new Date(),
                durationToDisplay: new Date()
            },

            activeDaysOfWeek: [] as any,
            isScheduleModalVisible: false as boolean,
            isDurationModalVisible: false as boolean,

            //Mass Media Upload
            isMassUploadModalVisible: false as boolean,
            isMassMediaUploading: false as boolean,
            showUploadFilesProgress: false as boolean,
            uploadFiles: [] as IFileDisplay[],
            uploadFilesProgressPercentage: '0',
            processedFileCount: 0,
            idBeingProcessed: '',
            dropArea: {} as HTMLElement,
            dropZoneHovered: false,
            showDropZone: true,
            totalFiles: 0,
            showTierDialog: false,
            hasListManagement: false as boolean,
            hasScheduling: false as boolean,

            // Url flags
            isUrlValid: false as boolean,
            invalidUrlText: 'Invalid URL' as string,

            // Icons
            // ======
            mdiCalendarClock,
            mdiClockTimeFive,
            mdiFormatListText,
            mdiDragHorizontalVariant,
            mdiCrosshairsGps,
            mdiInformationVariant,
            mdiVideoImage,
            mdiViewCarousel,
            mdiBookOpen,
            mdiSpotlight,
            mdiSocialDistance6Feet,
            mdiFaceMask,
            mdiStarBox,
            mdiTextBoxPlus,
            mdiAccountGroup,
            mdiViewSplitVertical,
            mdiMap,
            mdiWeb,
            mdiWeatherPartlyCloudy,
            mdiGridLarge,
            mdiHandWash,
            mdiDotsVertical,
            mdiDelete,
            mdiPencil,
            mdiMagnify,
            mdiChevronRight,
            mdiOpenInNew,
            mdiFullscreen,
            mdiWeatherCloudyClock,
            mdiHandWater,
            mdiEyeOff,
            mdiEye,
            mdiAlertCircle,
            mdiInformationOutline,
            mdiCursorMove,
            mdiCardMultiple,
            mdiExclamationThick,
            mdiDraw,
            mdiPresentation,
            mdiFolderAlert,
            mdiCloseCircleOutline,
            mdiPencilOutline,

            noAccessRooms: [] as IRoom[],

            modal: undefined as any | undefined,
            modalBody: undefined as any | undefined,
            modalForm: undefined as any | undefined,

            layoutTypes: [
                {
                    name:'media',
                    displayName: 'Media',
                    description:'Upload and display your own media files',
                    icon: mdiVideoImage
                },
                {
                    name:'singleitemmenu',
                    displayName: 'Menu Item',
                    description:'Feature a single item on your menu',
                    icon: mdiStarBox
                },
                {
                    name: 'multiitemmenu',
                    displayName: 'Full Menu',
                    description:'Display a list of items on a menu',
                    icon: mdiFormatListText
                },
                {
                    name:'wayfinding',
                    displayName: 'Wayfinding',
                    description:'Provide directions to a specific area on a floor or in a building',
                    icon: mdiCrosshairsGps
                },
                {
                    name:'informational',
                    displayName: 'Informational',
                    description:'Provide important information to visitors',
                    icon: mdiInformationVariant
                },
                {
                    name:'detailedinformational',
                    displayName: 'Detailed Informational',
                    description:'Provide important information to viewers',
                    icon: mdiBookOpen
                },
                {
                    name:'largeheadlineinformational',
                    displayName: 'Large Headline Informational',
                    description:'Provide important information to viewers',
                    icon: mdiTextBoxPlus
                },
                {
                    name:'spotlight',
                    displayName: 'Spotlight',
                    description:'Highlight someone in your organization',
                    icon: mdiSpotlight
                },
                {
                    name:'directory',
                    displayName: 'Directory',
                    description:'Show a list of names and locations',
                    icon: mdiMap
                },
                {
                    name:'feature',
                    displayName: 'Feature',
                    description:'Feature an event or deal',
                    icon: mdiViewCarousel
                },
                {
                    name:'featuretwo',
                    displayName: 'Feature 2',
                    description:'Feature an event, product, deal, etc.',
                    icon: mdiViewSplitVertical
                },
                {
                    name:'staticweburl',
                    displayName: 'Static Web URL',
                    description:'Display the static image of a webpage',
                    icon: mdiWeb
                },
                {
                    name:'weatherthreeday',
                    displayName: "Three-Day Weather",
                    description:'Display the weather forcast for the next 3 days',
                    icon: mdiWeatherCloudyClock
                },
                {
                    name:'weathertoday',
                    displayName: "Today's Weather",
                    description:'Display the weather forecast for today',
                    icon: mdiWeatherPartlyCloudy
                },
                {
                    name:'mediaquadrants',
                    displayName: 'Media Quadrants',
                    description:'Upload and display your own media files in quadrants',
                    icon: mdiGridLarge
                },
            ],
            covidLayoutTypes: [
                {
                    name: "covid-occupancy",
                    displayName: 'COVID-19 Occupancy',
                    description:'Display COVID-19 occupancy and health safety rules',
                    icon: mdiSocialDistance6Feet
                },
                {
                    name:'covidoccupancytwo',
                    displayName: 'COVID-19 Occupancy 2',
                    description:'Display how many people can occupy a space',
                    icon: mdiAccountGroup
                },
                {
                    name:'covid-safety',
                    displayName: 'COVID-19 Safety',
                    description:'Display COVID-19 health safety rules',
                    icon: mdiHandWater
                },
                {
                    name:'covidmask',
                    displayName: 'COVID-19 Mask',
                    description:'Display mask requirement',
                    icon: mdiFaceMask
                }
            ],
            integrationLayoutTypes: [
                {
                    name:'canva',
                    displayName: "Canva",
                    description:'Display a Canva design.',
                    icon: mdiDraw
                },
                {
                    name:'googleslidesimport',
                    displayName: "Google Slides",
                    description:'Create a signage list from a Google Slides presentation.',
                    icon: mdiPresentation
                }
            ],
            
            scheduleType: 'Day'
        }
    },

    components: {
        VueDatePicker,
        InlineTextLoader,
        Icon,
        draggable,
        SignageListSelectionModal,
        ModelSelector,
        TierLimit
    },

    methods: {
        async updateQuota() {
             this.isSignageQuotaLoading = true;

             await organizationState.fetchSignageAssetQuota();

             this.isSignageQuotaLoading = false;
        },

        async roomSelectionChanged(rooms: any) {
            const selectedRooms = rooms.filter((room: any)=> room.selected);
            
            const selectedRoomsId = selectedRooms.map((a: any)=> {
                return a.id;
            });

            let hydratedSelectedRooms = (this as any).allRooms.filter((room: any)=> {
                return selectedRoomsId.includes(room.id);
            });

            hydratedSelectedRooms = [...hydratedSelectedRooms, ...this.noAccessRooms];

            this.isSetRoomsLoading = true;

            try {
                const resp = await signageListState.setRoomList({...this.signageList, rooms: hydratedSelectedRooms as any } as ISignageList);

                this.isSetRoomsLoading = false;
                ElMessage({
                    message: `${ resp.data ? resp.data.name : '' } rooms updated`,
                    type: 'success',
                });
            }
            catch {
                ElMessage({
                    message: `Error updating rooms.`,
                    type: 'error',
                });
            }

            await this.setSignageObject(false);
        },

        sortByDay(a: any, b: any) {
            const first = this.daysOfWeek.find((day: any)=> day.value === a);
            const second = this.daysOfWeek.find((day: any)=> day.value === b);
            return first.order - second.order;
        },

        convert24to12(time: string) {
            return new Date ('1970-01-01T' + time + 'Z').toLocaleTimeString('en-US', { timeZone: 'UTC', hour12: true, hour: 'numeric', minute: 'numeric'});
        },

        validateDate(rule: any, value: any, callback: any) {
            if(this.massScheduleSettings.isDateEnabled && (!this.massScheduleSettings.dates[0] || !this.massScheduleSettings.dates[0])) {
                callback(new Error('Select start and end date.'));
            }
            callback();
        },

        validateTime(rule: any, value: any, callback: any) {
            if(this.massScheduleSettings.isTimeEnabled && (!this.massScheduleSettings.startTime || !this.massScheduleSettings.endTime)) {
                callback(new Error('Select start and end time.'));
            }
            callback();
        },

        validateDay(rule: any, value: any, callback: any) {
            if(this.massScheduleSettings.isDayEnabled && this.massScheduleSettings.activeDaysOfWeek.length < 1) {
                callback(new Error('Select days of the week.'));
            }
            callback();
        },

        validateScheduled(rule: any, value: any, callback: any) {
            if(this.massScheduleSettings.scheduledTimeSet && (!this.massScheduleSettings.isDayEnabled && !this.massScheduleSettings.isDateEnabled && !this.massScheduleSettings.isTimeEnabled)) {
                callback(new Error('Set schedule before saving.'));
            }
            callback();
        },

        overviewValidateDate(rule: any, value: any, callback: any) {
            if(this.signageList.isDateEnabled && (!this.signageList.startDate || !this.signageList.endDate)) {
                callback(new Error('Select start and end date.'));
            }
            callback();
        },

        overviewValidateTime(rule: any, value: any, callback: any) {
            if(this.signageList.isTimeEnabled && (!this.signageList.startTime || !this.signageList.endTime)) {
                callback(new Error('Select start and end time.'));
            }
            callback();
        },

        overviewValidateDay(rule: any, value: any, callback: any) {
            if(this.signageList.isDayEnabled && (!this.signageList.activeDaysOfWeek || this.signageList.activeDaysOfWeek.length == 0)) {
                callback(new Error('Select days of the week.'));
            }
            callback();
        },

        overviewValidateScheduled(rule: any, value: any, callback: any) {
            if(this.signageList.scheduledTimeSet && (!this.signageList.isDayEnabled && !this.signageList.isDateEnabled && !this.signageList.isTimeEnabled)) {
                callback(new Error('Set schedule before saving.'));
            }
            callback();
        },

        overviewSignageListName(rule: any, value: any, callback: any) {
            if(!this.signageList.name) {
                callback(new Error('Please enter a signage list name.'));
            }
            callback();
        },

        onDeleteClick(item?: ISignageItem | null) {
            this.signagesPoppedUp = [];
            if(item) {
                this.signagesPoppedUp.push(item);
            } else {
                this.signagesPoppedUp = this.selectedSignages;
            }
            this.isDeleteModalVisible = true;
        },
        
        async showHideSignageItems(item?: ISignageItem | null) {
             this.signagesPoppedUp = [];
            
            if(item) {
                this.signagesPoppedUp.push(item);
                this.isSingleSignagePoppedUp = true;
            } else {
                this.signagesPoppedUp = this.selectedSignages;
                this.isSingleSignagePoppedUp = false;
            }

            this.isSignageListItemsLoading = true;
            let setHideFromDisplay = false;

            if(!this.isSingleSignagePoppedUp) {
                setHideFromDisplay = !this.isSelectedHidden;
            }
            
            this.isShowHideLoading = true;

            let promiseList = [] as any;

            this.signagesPoppedUp.forEach((signage: ISignageItem) => {
                if(this.isSingleSignagePoppedUp) {
                    setHideFromDisplay = !signage.hideFromDisplay;
                }
                promiseList.push(signageItemState.updateSignageItemMeta({...signage as any, hideFromDisplay: setHideFromDisplay }));
            });


            const results = await Promise.all(promiseList as Promise<ISqStoreActionResponse<any>>[] ) as ISqStoreActionResponse<any>[];

            if(results.some(x => !x.success)) {
                ElMessage({
                    message: `Signage item update failed`,
                    type: 'error',
                });
            }
            
            await this.setSignageObject(true);

            ElMessage({
                message: `Successfully updated ${this.signagesPoppedUp.length } signage item${this.signagesPoppedUp.length > 1? 's': '' }`,
                type: 'success',
            });

            this.signageItemToShowHide = null;
            this.isShowHideLoading = false;

            this.selectedSignages = [];
            this.isSignageListItemsLoading = false;
        },

        handleSelectionChange(val:any) {
            this.selectedSignages = val;
        },

        onSignageItemCheck(item: any) {
            const idx = this.selectedSignages.findIndex((i: any) => i.id === item.id);
            if(idx > -1) {
                const idx = this.selectedSignages.findIndex((i: any) => i.id === item.id);
                this.selectedSignages.splice(idx, 1);
            } else {
               this.selectedSignages.push(item);
            }
        },

        onDurationModalClose(){
            this.isDurationModalVisible = false;
        },

        onScheduleModalClose(){
            this.isScheduleModalVisible = false;
        },

        onScheduleModalClosed() {
            (this.$refs['scheduleForm'] as any).clearValidate();
        },

        onSignageItemCheckAll(e: any) {
            if(this.isIndeterminate || !e) {
                this.selectedSignages = [];
            } else {
                this.orderedSignageItems.forEach((item: any)=> {
                    this.selectedSignages.push(item);
                })
            }
            
        },
        
        onMassDurationClick(signageList?: any) {
            this.signagesPoppedUp = [];

            if(!signageList && this.selectedSignages.length == 1) {
                signageList = this.selectedSignages[0];
            }

            if(signageList) {
                this.signagesPoppedUp.push(signageList);

                this.massDurationSettings = {
                    showDuration: signageList.finalSignageAsset && signageList.finalSignageAsset.assetType == 'image',
                    showLoop: signageList.finalSignageAsset && signageList.finalSignageAsset.assetType == 'video',
                    playUntilEnd: signageList.playUntilEnd,
                    durationToDisplay: this.formatDuration(signageList.durationToDisplay),
                    playUntilEndLoopCount: signageList.playUntilEndLoopCount
                }
            }
            else {
                this.signagesPoppedUp = this.selectedSignages;

                this.massDurationSettings = {
                    showDuration: this.signagesPoppedUp.some((signage: any) => signage.finalSignageAsset.assetType == 'image'),
                    showLoop: this.signagesPoppedUp.some((signage: any) => signage.finalSignageAsset.assetType == 'video'),
                    playUntilEnd: this.signagesPoppedUp.some((signage: any) => signage.playUntilEnd),
                    durationToDisplay: this.formatDuration(5),
                    playUntilEndLoopCount: 1
                }
            }

            this.isDurationModalVisible = true;
         },

         async onDurationSaveClick() {
            this.isDurationScheduleLoading = true;

            const durationToDisplaySeconds = (this.massDurationSettings.durationToDisplay.hours * 3600) + (this.massDurationSettings.durationToDisplay.minutes * 60) + this.massDurationSettings.durationToDisplay.seconds;
            
            let promiseList = [] as any;

            this.signagesPoppedUp.forEach(async (signageItem: ISignageItem) => {
                if(signageItem.finalSignageAsset && signageItem.finalSignageAsset.assetType == 'image') {
                    promiseList.push(signageItemState.updateSignageItemMeta({...signageItem as any, durationToDisplay: durationToDisplaySeconds}));
                }

                if(signageItem.finalSignageAsset && signageItem.finalSignageAsset.assetType == 'video') {
                    if(this.massDurationSettings.playUntilEnd) {
                        promiseList.push(signageItemState.updateSignageItemMeta({...signageItem as any, playUntilEnd: this.massDurationSettings.playUntilEnd, playUntilEndLoopCount: this.massDurationSettings.playUntilEndLoopCount}));
                    }
                    else {
                        promiseList.push(signageItemState.updateSignageItemMeta({...signageItem as any, playUntilEnd: this.massDurationSettings.playUntilEnd, durationToDisplay: durationToDisplaySeconds}));
                    }
                }
            });

            const results = await Promise.all(promiseList as Promise<ISqStoreActionResponse<any>>[] ) as ISqStoreActionResponse<any>[];

            if(results.some(x => !x.success)) {
                ElMessage({
                    message: `Signage item update failed`,
                    type: 'error',
                });
            }
            
            await this.setSignageObject(true);
            PageDirtyService.markPageClean();

            ElMessage({
                message: `Successfully updated ${this.signagesPoppedUp.length } signage item${this.signagesPoppedUp.length > 1? 's': '' }`,
                type: 'success',
            });

            this.isDurationScheduleLoading = false;
            this.isDurationModalVisible = false;

            this.selectedSignages = [];
            this.signagesPoppedUp = [];
        },

        onMassScheduleClick(signageList?: any) {
            if(!this.hasScheduling) {
                return;
            }

            this.signagesPoppedUp = [];

            if(!signageList && this.selectedSignages.length == 1) {
                signageList = this.selectedSignages[0];
            }

            if(signageList) {
                this.signagesPoppedUp.push(signageList);

                this.massScheduleSettings = {
                    isDayEnabled: signageList.isDayEnabled,
                    isDateEnabled: signageList.isDateEnabled,
                    isTimeEnabled:signageList.isTimeEnabled,
                    scheduledTimeSet: signageList.scheduledTimeSet,
                    activeDaysOfWeek: signageList.activeDaysOfWeek ? [...signageList.activeDaysOfWeek]  : [],
                    startTime: {
                        hours: signageList.startTime ? signageList.startTime.split(':')[0] : 0,
                        minutes: signageList.startTime ? signageList.startTime.split(':')[1] : 0,
                        seconds: 0
                    },
                    endTime: {
                        hours: signageList.endTime ? signageList.endTime.split(':')[0] : 0,
                        minutes: signageList.endTime ? signageList.endTime.split(':')[1] : 0,
                        seconds: 0
                    },
                    dates: [signageList.startDate, signageList.endDate]
                } as any;

            } else {

                this.signagesPoppedUp = this.selectedSignages;
                this.massScheduleSettings = {
                    isDayEnabled: false as boolean,
                    isDateEnabled: false as boolean,
                    isTimeEnabled: false as boolean,
                    scheduledTimeSet: false as boolean,
                    activeDaysOfWeek: [] as string[],
                    startTime: null,
                    endTime: null,
                    dates: [] as Date[],
                } as any;
            }

            this.isScheduleSaveWarningVisible = this.signagesPoppedUp.length > 1 && this.signagesPoppedUp.some((signage: ISignageItem) => signage.scheduledTimeSet);
            this.isScheduleModalVisible = true;
        },

        async onScheduleSaveClick(){
            this.isSignageListItemsLoading = true;
            let isValid = false;

            try {
                isValid = await (this.$refs['scheduleForm'] as any).validate();
            } catch (error: any) {
                let errorHTML = '';
                for(const key in error) {
                    for(const err of error[key]) {
                        errorHTML = `${errorHTML}${err.message}<br>`;
                    }
                }

                ElMessage({
                    dangerouslyUseHTMLString: true,
                    message: errorHTML,
                    type: 'error',
                });

                return;
            }

            if(!isValid) return;

            const properties = {
                isDayEnabled: this.massScheduleSettings.isDayEnabled,
                isDateEnabled: this.massScheduleSettings.isDateEnabled,
                isTimeEnabled: this.massScheduleSettings.isTimeEnabled,
                scheduledTimeSet: this.massScheduleSettings.scheduledTimeSet,
                activeDaysOfWeek: this.massScheduleSettings.activeDaysOfWeek,
                startTime: this.massScheduleSettings.startTime ? new Date(`2022/1/1 ${this.massScheduleSettings.startTime.hours}:${this.massScheduleSettings.startTime.minutes}:${this.massScheduleSettings.startTime.seconds}`).toTimeString().split(' ')[0] as any : new Date(`2022/1/1 00:00:00`).toTimeString().split(' ')[0] as any,
                endTime: this.massScheduleSettings.endTime ? new Date(`2022/1/1 ${this.massScheduleSettings.endTime.hours}:${this.massScheduleSettings.endTime.minutes}:${this.massScheduleSettings.endTime.seconds}`).toTimeString().split(' ')[0] : new Date(`2022/1/1 00:30:00`).toTimeString().split(' ')[0] as any,
                startDate:this.massScheduleSettings.dates[0],
                endDate:this.massScheduleSettings.dates[1]
            } as any;

            const payload = {
                scheduleProperties: properties as IScheduleProperties,
                signageListId: this.selectedSignageListId as string,
                signageItems: this.signagesPoppedUp as ISignageItem[]
            } as IScheduleSignageItemPayload;

            this.isSignageScheduleLoading = true;

            const resp = await signageItemState.scheduleSignageItems(payload);
       
            if(!resp.success){ 
                ElMessage({
                    message: `Signage item schedule update failed`,
                    type: 'error',
                });

                this.isScheduleModalVisible = false;
                this.isSignageScheduleLoading = false;
                
                return;
            }

            this.isScheduleModalVisible = false;
            this.isSignageScheduleLoading = false;

            await this.setSignageObject(false);
            await signageListState.fetchScheduleConflicts();

            ElMessage({
                message: `Successfully scheduled ${ resp.data.length } signage lists`,
                type: 'success',
            });

            this.selectedSignages = [];
            this.isSignageListItemsLoading = false;
        },

        onDayOfWeekClick(day: any) {
            if(!this.massScheduleSettings.isDayEnabled || !this.massScheduleSettings.scheduledTimeSet) return;

            const index = this.massScheduleSettings.activeDaysOfWeek.findIndex((d: any) => d === day.value);
            if(index > -1) {
                this.massScheduleSettings.activeDaysOfWeek.splice(index,1);
            } else {
                this.massScheduleSettings.activeDaysOfWeek.push(day.value);
            }
        },

        onDetailsDayOfWeekClick(dayValue: any){
            if(!this.signageList.isDayEnabled || !this.signageList.scheduledTimeSet) return;

            if(this.activeDaysOfWeek) {
                const index = this.activeDaysOfWeek.findIndex((d: any) => d === dayValue);
                if(index > -1) {
                    this.activeDaysOfWeek.splice(index,1);
                } else {
                    this.activeDaysOfWeek.push(dayValue);
                }
            }
            else {
                this.activeDaysOfWeek = [];
                this.activeDaysOfWeek.push(dayValue);
            }

            this.signageList.activeDaysOfWeek = this.activeDaysOfWeek;
        },
        
        onContextClick(el: any) {
            document.addEventListener('click', this.closeContextMenu, true);
            if(el.id == this.openSignageItemContextId) {
                this.closeContextMenu();
            } else {
                this.openSignageItemContextId = el.id;
            }
        },

        closeContextMenu(){
            this.$nextTick(()=> {
                this.openSignageItemContextId = null;
                document.removeEventListener('click', this.closeContextMenu, true);  
            });
        },

        onNewItemHandler() {
            if(!this.canAddSignageItems || this.isStorageFull) {
                this.showTierDialog = true;
                return;
            }

            this.layoutTypeChoiceVisibile = true;
            // Initialize the modal if we havent opened it yet
            if(!this.modal){
                this.$nextTick(()=> {
                    this.modal = this.$refs.layoutChoiceModal as any
                    this.modalBody = this.modal.$el.nextSibling.querySelectorAll('.el-dialog__body')[0];
                    this.modalForm = this.modal.$el.nextSibling.querySelectorAll('.el-form')[0];
                });
            }
            //Force recalculation of height whenever the modal is opened
            this.onLayoutChoiceTabClick();
        },

        onLayoutChoiceTabClick(){
            this.selectedLayoutType = {} as any;

            if(this.modalBody){
                this.modalBody.style.height = `calc(${ this.modalForm.scrollHeight + 'px' } + 4em)`;
            }
            this.$nextTick(()=> {
                if(this.modalBody){
                    this.modalBody.style.height = `calc(${ this.modalForm.scrollHeight + 'px' } + 4em)`;
                }
            });
        },

        //#region New Item Modal Methods
        onInitializeSignageItem() {
            this.$router.push(`/signage/${this.signageList.id}/new/${this.selectedLayoutType.name}`);
            this.layoutTypeChoiceVisibile = false;
        },

        onCancelLayoutTypeChoice() {
            this.layoutTypeChoiceVisibile = false;
        },

        onClosedLayoutTypeChoice() {
            this.selectedLayoutType = {} as any;
            this.selectedLayoutTypeTab = 'general';
        },

        onSubmitLayoutTypeChoice() {
            this.layoutTypeChoiceVisibile = false;
        },

        onCloseLayoutTypeChoice() {
            this.layoutTypeChoiceVisibile = false;
        },

        onSelectLayoutType(layoutType:any) {
            if(this.selectedLayoutType == layoutType) {
                this.selectedLayoutType = {} as any;
            }
            else {
                this.selectedLayoutType = layoutType;
            }
        },

        isLayoutDisbabled(layoutType: string) {
            if(layoutType == 'media') {
                return false;
            }

            if(layoutType == 'canva' && !tierState.orgTier.signageCanvaEnabled) {
                return true;
            }

            if(layoutType == 'twitter' && !tierState.orgTier.signageTwitterEnabled) {
                return true;
            }

            if(!tierState.orgTier.sigangeTemplatesEnabled) {
                return true
            }
    
            return false;
        },

        layoutToolTipText(layoutType: any): string {
            let name = ''
            if(layoutType.displayName) {
                name = layoutType.displayName;
            }
            else {
                name = this.setLayoutTypeDisplayName(layoutType);
            }
            return `Upgrade your Ditto account to access the ${name} layout.`;
        },
        //#endregion

        inlineSignageItemRenameHandler(element: any) {
            this.signageItemNameBeingEditedId = element.id;
            this.$nextTick(() => {
                const input = (this as any).$refs[`inlineSignageItemRenameInput${ element.id }`];
                input.focus();
            });
        },

        editListNameInputBlurHandler() {
            this.isEditingListName = false;
        },

        onTimePickerUpdate(newValue: any, element: any, timeType: string){
            const idx = this.mutatableSignageItemsStateObject.signage.findIndex((x: any)=> x.id== element.id);

            // This could possibly be broke up into 3 different time-picker update
            // handlers, but for now we can keep it as a switch instead of 3 different methods
            switch(timeType) {
                case 'startTime':
                    this.mutatableSignageItemsStateObject.signage[idx].startTime = moment(`2022/1/1 ${newValue.hours}:${newValue.minutes}:${newValue.seconds}`).format('HH:mm:ss');
                    break;
                case 'endTime':
                    this.mutatableSignageItemsStateObject.signage[idx].endTime = moment(`2022/1/1 ${newValue.hours}:${newValue.minutes}:${newValue.seconds}`).format('HH:mm:ss');
                    break;
                case 'durationToDisplay':
                    this.mutatableSignageItemsStateObject.signage[idx].durationToDisplay = (newValue.hours * 60 * 60) + (newValue.minutes * 60) + (newValue.seconds);
                    break;
                default:
                    break;
            }
        },

        displayDuration(seconds: number) : string {
            var date = new Date(0);
            date.setSeconds(seconds);
            return date.toISOString().substr(11, 8);
        },

        displayLoop(loopCount: number): string {
            return `${loopCount} loop${loopCount > 1? 's': '' }`
        },

        async onSignageListSave() {
            let isValid = false;

            try {
                isValid = await (this as any).$refs['overviewScheduleForm'].validate();
            } catch (error: any) {
                let errorHTML = '';
                for(const key in error) {
                    for(const err of error[key]) {
                        errorHTML = `${errorHTML}${err.message}<br>`;
                    }
                }

                ElMessage({
                    dangerouslyUseHTMLString: true,
                    message: errorHTML,
                    type: 'error',
                });

                return;
            }

            if(!isValid) return;

            if(this.isSaving) return;
            this.isSaving = true;

            const promiseList = [];
            
            // if the list has changed save it...
            if(this.isImport || (!deepEqual(this.signageList, signageListState.signageListById(this.selectedSignageListId)))) {
                const scheduleProperties = {
                    isDayEnabled: this.signageList.isDayEnabled,
                    isDateEnabled: this.signageList.isDateEnabled,
                    isTimeEnabled: this.signageList.isTimeEnabled,
                    scheduledTimeSet: this.signageList.scheduledTimeSet,
                    activeDaysOfWeek: this.signageList.activeDaysOfWeek,
                    startTime: this.signageList.startTime ? this.signageList.startTime : new Date(`2022/1/1 00:00:00`).toTimeString().split(' ')[0] as any,
                    endTime: this.signageList.endTime ? this.signageList.endTime : new Date(`2022/1/1 00:30:00`).toTimeString().split(' ')[0] as any,
                    startDate:this.signageList.startDate,
                    endDate:this.signageList.endDate
                } as any;

                const schedulePayload = {
                    scheduleProperties: scheduleProperties as IScheduleProperties,
                    signageListId: this.selectedSignageListId,
                    signageLists: [this.signageList] as ISignageList[]
                } as IScheduleSignageListPayload;
                
                promiseList.push(signageListState.scheduleSignageLists(schedulePayload));
                promiseList.push(signageListState.updateSignageList(this.signageList));
            }

            for(const signageItem of this.mutatableSignageItemsStateObject.signage) {
                const reactiveSignageItemsStateObject = await signageItemState.signageForList(this.selectedSignageListId, true);
                if(!deepEqual(signageItem, reactiveSignageItemsStateObject.signage.find((x:ISignageItem) => x.id === signageItem.id))) {
                    promiseList.push(signageItemState.updateSignageItemMeta(signageItem));
                }
            }
            
            const results = await Promise.all(promiseList as Promise<ISqStoreActionResponse<any>>[] ) as ISqStoreActionResponse<any>[];
            
            if(results.some(x => !x.success)) {
                ElMessage({
                    message: `Signage list update failed`,
                    type: 'error',
                });
                this.isSaving = false;
                return;
            }

            await this.setSignageObject(true);
            await signageListState.fetchScheduleConflicts();
            
            PageDirtyService.markPageClean();

            ElMessage({
                message: `Signage list saved`,
                type: 'success',
            });

            this.isSaving = false;

            this.updateQuota();
        },

        async setSignageObject(reload: boolean) {
            this.signageList = signageListState.allSignageLists.find((list: any) => list.id === this.selectedSignageListId) as any; 
            this.activeDaysOfWeek = this.signageList.activeDaysOfWeek ? [...this.signageList.activeDaysOfWeek] : [];
            if(this.signageList.rooms.length > 0 && !this.signageList.rooms.some((x: any) => x.roomName)) {
                const roomsIds = this.signageList.rooms.map((x: any) => x.id);
                this.signageList.rooms = (this as any).allRooms.filter((x: any) => roomsIds.includes(x.id));
            }
            this.mutatableSignageItemsStateObject = await signageItemState.signageForList(this.selectedSignageListId, reload);
        },
        
        async loadSignageForList() {
            if (this.signageList) {
                this.isSignageListItemsLoading = true;
            }
            // load the state store with the signage data for the list
            await signageItemState.fetchSignageForList({signageListId: this.selectedSignageListId, forceReload: true });
            
            // get the list data out of the state store
            await this.setSignageObject(true);
            this.isSignageListItemsLoading = false;
        },

        toggleManageRooms() {
           let roomsMap: IModelSelectionPool[] = (this as any).allRooms.map((room: IRoom) =>{
                return {
                    id: room.id,
                    name: room.roomName,
                    selected: this.signageList.rooms.map((r: IRoom) => r.id).includes(room.id),
                    disabled: false
                }
            });

            this.noAccessRooms = this.signageList.rooms.filter( (x: any) => { return !roomsMap.map(y => y.id).includes(x.id) });

            const noAccessRoomsMap: IModelSelectionPool[] = this.noAccessRooms.map((room: IRoom) =>{
                return {
                    id: room.id,
                    name: room.roomName,
                    selected: true,
                    disabled: true
                } as any
            });

            roomsMap = [...roomsMap, ...noAccessRoomsMap];

            roomsMap.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));

            this.showNoAccessRoomMessage = roomsMap.some((x: any) => x.disabled == true);
            
            (this as any).$refs['roomModelSelector'].activate(roomsMap);
        },

        toggleEditListNameInput() {
            this.isEditingListName = !this.isEditingListName;
            this.$nextTick(()=> {
                const input = this.$refs['editListNameInput'] as any;
                input.focus();
            });
        },

        onHideShowList() {
            if(this.signageList.hideListFromDisplay) {
                this.signageList.hideListFromDisplay = false;
            }
            else{
                this.signageList.hideListFromDisplay = true;
            }
        },

        async changeDetection() {
            if(!this.selectedSignageListId) { return }
            // We have to make sure that the signages are sorted...
            const signageStateItem = await signageItemState.signageForList(this.selectedSignageListId, true);
            signageStateItem.signage = signageStateItem.signage.sort((a: any, b: any) => a.order?a.order:0 - b.order?b.order:0);

            const originalSignageList = signageListState.signageListById(this.selectedSignageListId) as any;

            //we don't want to monitor the fields that the event emitter is updating that the user cannot update
            const originalSignage = JSON.parse(JSON.stringify(signageStateItem)) as any;
            for (const signage of originalSignage.signage) {
                delete signage.finalSignageAsset;
                delete signage.finalAssetGenerationError;
                delete signage.wipThumb;
            }
            
            const editedSignage = JSON.parse(JSON.stringify(this.mutatableSignageItemsStateObject)) as any;
            for (const signage of editedSignage.signage) {
                delete signage.finalSignageAsset;
                delete signage.finalAssetGenerationError;
                delete signage.wipThumb;
            }   

            if(this.signageList.importUrl && originalSignageList.importUrl !== this.signageList.importUrl) {
                const url = new URL(this.signageList.importUrl);
                const timeString = url.searchParams.get('delayms');
                const durationSeconds = Number(timeString) > 0 ? Number(timeString)/1000 : this.signageList.durationToDisplay ? this.signageList.durationToDisplay : 5;
                this.signageList.durationToDisplay = durationSeconds;
            }

            PageDirtyService.monitorObjectSet([
                {defaultObject: originalSignage, mutatedObject: editedSignage},
                {defaultObject: originalSignageList.name, mutatedObject: this.signageList.name},
                {defaultObject: originalSignageList.hideListFromDisplay, mutatedObject: this.signageList.hideListFromDisplay},
                {defaultObject: originalSignageList.isDayEnabled, mutatedObject: this.signageList.isDayEnabled},
                {defaultObject: originalSignageList.isDateEnabled, mutatedObject: this.signageList.isDateEnabled},
                {defaultObject: originalSignageList.isTimeEnabled, mutatedObject: this.signageList.isTimeEnabled},
                {defaultObject: originalSignageList.scheduledTimeSet, mutatedObject: this.signageList.scheduledTimeSet},
                {defaultObject: originalSignageList.activeDaysOfWeek, mutatedObject: this.signageList.activeDaysOfWeek},
                {defaultObject: originalSignageList.startTime, mutatedObject: this.signageList.startTime},
                {defaultObject: originalSignageList.endTime, mutatedObject: this.signageList.endTime},
                {defaultObject: originalSignageList.startDate, mutatedObject: this.signageList.startDate},
                {defaultObject: originalSignageList.endDate, mutatedObject: this.signageList.endDate},
                {defaultObject: originalSignageList.durationToDisplay, mutatedObject: this.signageList.durationToDisplay}
            ]);
        },

        setLayoutTypeDisplayName(layoutTypeName: any) {
            const allLayoutTypes = [...this.layoutTypes, ...this.covidLayoutTypes, ...this.integrationLayoutTypes];

            const layout = allLayoutTypes.find((layoutType: any) => layoutType.name == layoutTypeName);
            
            if (!layout) return '';

            return layout.displayName;
        },

        //#region List management
        //Delete
        async massDelete() {
            this.isSignageListItemsLoading = true;

            if(this.signagesPoppedUp.length > 0) {
                this.signagesToDelete = this.signagesPoppedUp;
            }

            if(this.signagesToDelete.length > 0) {
                this.isSignageDeleteLoading = true;
                for(const signage of this.signagesToDelete) {
                    const deletePayload = {
                        signageListId: this.$route.params.listid,
                        signageItem: signage
                    } as IDeleteSignageItemPayload;
                    
                    const results = await signageItemState.deleteSignageItem(deletePayload);
                    if(!results.success) {
                        ElMessage({
                            message: `Signage deletion failed`,
                            type: 'error',
                        });
                        this.isSignageDeleteLoading = false;
                        this.isDeleteModalVisible = false;
                        return;
                    }
                }
                
                await this.setSignageObject(false);
                await signageListState.fetchScheduleConflicts();
                await tierState.fetchAddSignageItemCheck(true);

                this.selectedSignages = [];
                this.signagesToDelete = [];
                
                ElMessage({
                    message: `Successfully deleted signage item${ this.selectedSignages.length > 1 ? 's' : '' }.`,
                    type: 'success',
                });

                //Reorder signages after delete
                if(this.orderedSignageItems.length > 0) {
                    const reorderedList: ISignageItemReorderViewModel[] = this.orderedSignageItems.map( (signage: any, index: number) => {
                        return {
                        signageItemId: signage.id,
                        order: index + 1
                        } as ISignageItemReorderViewModel
                    })

                    const reorderPayload = {
                        signageListId: this.selectedSignageListId,
                        reorderedList: reorderedList
                    } as IReorderSignagePayload
                    
                    await signageItemState.reorderList(reorderPayload);
                    await this.setSignageObject(true);
                }

                this.isSignageDeleteLoading = false;
                this.isDeleteModalVisible = false;
                this.isSignageListItemsLoading = false;

                this.updateQuota();
            }
        },

        async deleteSignageItemHandler(item: ISignageItem) {
            this.selectedSignages = [];
            this.signagesToDelete = [];
            this.signagesToDelete.push(item);
            this.selectedSignages.push(item);
            this.isDeleteModalVisible = true;
        },

        //Hide show
        massHideShow() {
            const setHideFromDisplay = !this.isSelectedHidden;
            this.selectedSignages.forEach((signage : ISignageItem) => {
                const index = this.orderedSignageItems.findIndex((x => x.id == signage.id));
                if(index != -1) {
                    this.orderedSignageItems[index].hideFromDisplay = setHideFromDisplay;
                }
            })
            this.selectedSignages = [];
        },

        hideItemHandler:function(item: ISignageItem) {
            this.selectedSignages = [];
            if(item.hideFromDisplay) {
                item.hideFromDisplay = false;
            }
            else {
                item.hideFromDisplay = true;
            }
            
            //MS: close menu
            this.closeContextMenu();
        },

        //Duplicate
        async duplicateSignages() {
            if(this.projectedNewCount > this.signageTierLimit || !this.canAddSignageItems || this.isStorageFull) {
                this.showTierDialog = true;
                return;
            }

           
            this.isSignageListItemsLoading = true;

            if(this.selectedSignages.length > 0) {
                this.signagesToDuplicate = this.selectedSignages;
            }

            const payload = {
                signageList: this.signageList,
                signagesToDuplicate: this.signagesToDuplicate
            }

            const promiseList = [] as any;
            promiseList.push(signageItemState.duplicateSignageItems(payload));

            const results = await Promise.all(promiseList as Promise<ISqStoreActionResponse<any>>[] ) as ISqStoreActionResponse<any>[];

            if(results.some(x => !x.success)) {
                ElMessage({
                    message: `Signage duplication failed`,
                    type: 'error',
                });
                this.isSignageListItemsLoading = false;
                return;
            }

            this.selectedSignages = [];
            this.signagesToDuplicate = [];
            await this.setSignageObject(false);
            await signageListState.fetchScheduleConflicts();
            await tierState.fetchAddSignageItemCheck(true);
            
            ElMessage({
                message: `Signage item${this.selectedSignages.length > 1 ? 's' : ''} duplicated`,
                type: 'success',
            });

            this.isSignageListItemsLoading = false;
            this.updateQuota();
        },

        async duplicateItemHandler(item: ISignageItem) {
            if(!this.canAddSignageItems || this.isStorageFull) {
                this.showTierDialog = true;
                return;
            }

            this.selectedSignages = [];
            this.signagesToDuplicate = [];
            this.signagesToDuplicate.push(item);

             // MS: Close the menu
            this.closeContextMenu();

            await this.duplicateSignages();
        },

        //Move
        toggleListSelectModal() {
            this.poppedUpList = JSON.parse(JSON.stringify(this.signageList));
            this.allSignageLists = this.getAllSignageLists();
            this.moveListVisible = true;
        },

        async moveSignagesHandler(newList: ISignageList) {
            this.isSignageListsMoveLoading = true;
            // if(this.selectedSignages.length > 0) {
            //     this.signagesPoppedUp = this.selectedSignages;
            // }

            if(newList) {
                const payload = {
                    currentSignageList: this.signageList,
                    newSignageList: newList,
                    sigangesToMove: this.signagesPoppedUp
                }

                const promiseList = [] as any;
                promiseList.push(signageItemState.moveSignageItems(payload));

                const results = await Promise.all(promiseList as Promise<ISqStoreActionResponse<any>>[] ) as ISqStoreActionResponse<any>[];

                if(results.some(x => !x.success)) {
                    ElMessage({
                        message: `Signage move failed`,
                        type: 'error',
                    });
                    this.moveListVisible = false;
                    this.isSignageListsMoveLoading = false;
                    return;
                }
            }
    
            // this.selectedSignages = [];
            this.signagesPoppedUp = [];
            await this.setSignageObject(false);
            await signageListState.fetchScheduleConflicts();

            ElMessage({
                message: `Signage item${this.selectedSignages.length > 1 ? 's' : ''} moved`,
                type: 'success',
            });
            this.moveListVisible = false;
            this.isSignageListsMoveLoading = false;
        },

        moveItemHandler(item?: ISignageItem  | null) : void {
            this.signagesPoppedUp = [];

            if(item) {
                this.signagesPoppedUp.push(item);
            } else {
                this.signagesPoppedUp = this.selectedSignages;
            }
            
            // MS: Close the menu
            this.closeContextMenu();
            this.toggleListSelectModal();
        },

        isHidden(item: ISignageItem): boolean {
            if(item) {
                if(item.hideFromDisplay) return true;
            }
            return false;
        },

        displayText(item: ISignageItem): string {
            if(item) {
                if(item.hideFromDisplay) return 'Show';
                return 'Hide';
            }
            return '';
        },

        allowDuplicate(item: ISignageItem) : boolean {
            if(item && item.finalSignageAsset) { return !item.finalSignageAsset.remoteUploadPending;}
            return false;
        },

        handleSelectAllChange() {
           if(this.selectedSignages.length == 0 || this.isIndeterminate) {
                this.selectedSignages = this.orderedSignageItems;
                return;
            }
            if(this.selectedSignages.length == this.orderedSignageItems.length) {
               this.selectedSignages = [];
               return;
            }
        },

        getAllSignageLists(): ISignageList[] {
            return signageListState.allSignageLists;
        },
        //#endregion

        updateSignagesFromMessage() {
            if(this.orderedSignageItems && this.orderedSignageItems.length > 0 && this.signageMessageUpdates && this.signageMessageUpdates.length > 0) {
                this.orderedSignageItems.forEach((signage: ISignageItem) => {
                    const updateMessage = this.signageMessageUpdates.find((x: IMessageSignageUpdate) => x.signageId === signage.id);
                    if(updateMessage) {   
                        signage.finalSignageAsset = updateMessage.finalSignageAsset;
                        signage.finalAssetGenerationError = updateMessage.finalAssetGenerationError;
                        signage.wipThumb = updateMessage.wipThumb;
                    }
                });
            }
        },

        async updateSignageListsFromMessage() {
            if(this.signageListMessageUpdates && this.signageListMessageUpdates.messageId) {
                this.messageDebounce = setTimeout(()=> {
                    if(this.reloadDebounce) clearTimeout(this.reloadDebounce);
                    this.reloadDebounce = setTimeout(async ()=> {
                        await this.loadSignageForList();
                        this.signageList.importError = this.signageListMessageUpdates.importError;
                        if(this.messageDebounce) clearTimeout(this.messageDebounce);
                    }, 3000);
                }, 15000);
            }
        },

        isListScheuleConflicted(signageId: string): boolean {
            const conflict = signageListState.hasSchedultConflict(signageId);
            return conflict;
        },

        onRowClick(row: any) {
            // if(this.isImport) { return }
            this.$router.push('/signage/' + this.selectedSignageListId + '/' + row.id);
        },

         formatDuration(duration: number) {
            if(duration) {
                let sec_num = duration;
                let hours   = Math.floor(sec_num / 3600) as any;
                let minutes = Math.floor((sec_num - (hours * 3600)) / 60) as any;
                let seconds = sec_num - (hours * 3600) - (minutes * 60) as any;

                if (hours   < 10) { hours   = "0" + hours; }
                if (minutes < 10) { minutes = "0" + minutes; }
                if (seconds < 10) { seconds = "0" + seconds; }

                const date = new Date(2021, 1, 1, hours, minutes, seconds);
                return {
                    hours: date.getHours(),
                    minutes: date.getMinutes(),
                    seconds: date.getSeconds()
                }
            }
        },

        //#region Mass Media Upload
        onMassUploadClick() {
            if(!this.canAddSignageItems || this.isStorageFull) {
                this.showTierDialog = true;
                return;
            }

            this.isMassUploadModalVisible = true;
        },

        tableRowStatus({row} : any) {
            if(row && !row.isValid) {
                return 'warning-row';
            }
            if(row && row.uploadFailed) {
                return 'failed-row';
            }
            if(row && row.id == this.idBeingProcessed) {
                return 'processing-row';
            }
            return '';
        },

        dragEnterHandler: function(e: DragEvent) {
            e.preventDefault();
            e.stopPropagation();
            this.dropZoneHovered = true;
        },

        dragLeaveHandler: function(e: DragEvent) {
            e.preventDefault();
            e.stopPropagation();
            this.dropZoneHovered = false;
        },

        dragOverHandler: function(e: DragEvent) {
            e.preventDefault();
            e.stopPropagation();
            this.dropZoneHovered = true;
        },

        dropHandler: function(e: DragEvent) {
            e.preventDefault();
            e.stopPropagation();
            this.dropZoneHovered = false;
            if(e && e.dataTransfer) {
                this.newFileHandler(e.dataTransfer.files); 
            }
        },

        newFileHandler: async function(files: FileList) {
            this.showDropZone = false;
            this.isMassMediaUploading = true;
            
            const filesArray = Array.from(files);
            for(const file of filesArray){
                const newFile = {} as IFileDisplay;
                newFile.id = uuidv4();
                newFile.uploadFile = file;

                const mediaUploadValidationResult = await DigitalAssetUploadService.validateFileUpload(newFile.uploadFile, true, 300);
                
                if(!mediaUploadValidationResult.isValid) {
                    newFile.isValid = false;
                    newFile.invalidReason = mediaUploadValidationResult.invalidReason;
                } else {
                    newFile.isValid = true;
                }

                newFile.img = await DigitalAssetUploadService.getFilePreview(newFile.uploadFile);
                newFile.isVideo = mediaUploadValidationResult.isVideoFile;
                if(newFile.isVideo) {
                    newFile.preview = URL.createObjectURL(newFile.uploadFile);
                    newFile.playSound = false;
                }
                else {
                    newFile.preview = newFile.img;
                }

                if(!mediaUploadValidationResult.isValid && newFile.invalidReason == 'Invalid file type') {
                    newFile.preview = 'images/signage-layout-images/staticweburlloading.png';
                }

                this.uploadFiles.push(newFile);
            }
        
            this.isMassMediaUploading = false;
            return;
        },

        onRemove(file: IFileDisplay) {
            if(this.isMassMediaUploading) { return; }

            const index = this.uploadFiles.indexOf(file);
            if (index > -1) {
                this.uploadFiles.splice(index, 1);
                if(this.uploadFiles.length == 0) {
                    this.clearFiles();
                }
            }

            return;
        },

        clearFiles() {
            this.uploadFiles = [];
            this.showDropZone = true;
        },

        imageTooltipText(file: IFileDisplay): string {
            if(!file.isValid) {
                return `${file.invalidReason}. File will not be uploaded.`
            }

            if(file.uploadFailed) {
                return 'File upload failed. Signage was not created.'
            }

            return '';
        },

        updatePlaySound(file: IFileDisplay) {
            const idx = this.uploadFiles.indexOf(file);
            const cloneFile = {...this.uploadFiles[idx]};
            cloneFile.playSound = file.playSound;
            this.uploadFiles.splice(idx, 1, cloneFile);
        },

        async onMassUpload() {
            PageDirtyService.markPageDirty();
            
            this.showUploadFilesProgress = true;
            this.isMassMediaUploading = true;
            
            // Reset files with errors
            const errorFiles = this.uploadFiles.filter((x: any) => x.uploadFailed)
            if(errorFiles.length > 0) {
                for(const file of errorFiles){
                    if(file.uploadFailed) {
                        const idx = this.uploadFiles.indexOf(file);
                        const cloneFile = {...this.uploadFiles[idx]};
                        cloneFile.uploadFailed = false;
                        this.uploadFiles.splice(idx, 1, cloneFile);
                    }
                }
            }

            this.totalFiles = this.uploadFiles.length;
            const filesToUpload = [...this.uploadFiles] as IFileDisplay[];
            let fileCount = 0;
            
            for(const file of filesToUpload){
                if(file.isValid) {
                    if(!this.isStorageFull && this.canAddSignageItems) {
                        this.idBeingProcessed = file.id;

                        const newMediaSignage = {
                            name: file.uploadFile.name,
                            layoutType: 'media',
                            layoutData: {
                                media: {
                                    img: file.img
                                }
                            },
                            signageList: this.signageList.id
                        } as ISignageItem;
                    
                        if(file.isVideo) {
                            newMediaSignage.playSound = file.playSound ? true : false;
                        }

                        const payload = {
                            signageListId: this.$route.params.listid as string,
                            signageItem: newMediaSignage
                        } as any;

                        signageItemState.clearFileQueue();
                        const filePayload = { file: file.uploadFile, layoutProperty: 'img', layoutType: 'media' };
                        signageItemState.fileAddedHandler(filePayload);
            
                        const saveResponse = await signageItemState.saveSignageItem(payload) as any;
                        if(!saveResponse.success || !saveResponse.data.id) {
                            file.uploadFailed = true;
                            this.idBeingProcessed = '';
                        }
                        else {
                            fileCount++;
                            await this.updateQuota();
                            await tierState.fetchAddSignageItemCheck(true);
                        }
                    }
                    else {
                        file.uploadFailed = true;
                        this.idBeingProcessed = '';
                    }        

                    this.uploadFiles = this.uploadFiles.filter((x: IFileDisplay) => x.id != this.idBeingProcessed);
                    
                    this.processedFileCount = fileCount;
                    this.uploadFilesProgressPercentage =((this.processedFileCount/this.totalFiles) * 100).toFixed(0);  
                }
            }

            this.showUploadFilesProgress = false;

            await this.setSignageObject(true);
            await signageListState.fetchScheduleConflicts();
            this.processedFileCount = 0;
            this.idBeingProcessed = '';

            this.isMassMediaUploading = false;

            if(this.uploadFiles.length == 0)
            {
                this.clearFiles();
                this.isMassUploadModalVisible = false;
            }
            else {
                this.totalFiles = this.uploadFiles.length;
            }

            PageDirtyService.markPageClean();
        },

        cancelMassMediaUpload() {
            this.clearFiles();
            this.isMassUploadModalVisible = false;
        },
        //#endregion

        async checkPresentationLink() {
            if(!this.signageList.importUrl || this.signageList.importUrl == '') {
                this.isUrlValid = true;
            }
            else{ 
                const regex = new RegExp("https?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,20}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)");
                if(this.signageList.importUrl && regex.test(this.signageList.importUrl)) {
                    const url = new URL(this.signageList.importUrl);
                    this.invalidUrlText = 'Invalid URL';

                    if(url) {
                        const pathname = url.pathname;
                        if(pathname == '/') { this.isUrlValid = false; }

                        const paths = pathname.split('/');
                        const presentationId = paths.find(x => x != 'presentation' && x.length > 4);
                        if(!url.href.includes('presentation') || !presentationId || url.href.includes('/edit') || !url.href.includes('/pub')) {
                            this.isUrlValid = false;
                            this.invalidUrlText = 'This presentation must be published in Google Slides to use as digital signage.';
                        } else {
                            this.isUrlValid = true;
                        }
                    } else {
                        this.isUrlValid = false;
                    }
                }
                else {
                    this.isUrlValid = false;
                }
            }
        },

        canEditName(layoutType: string): boolean {
            if(layoutType === 'canva' || layoutType === 'googleslidesimport' ) {
                return false;
            }
            return true;
        }
    },

    props: [
        'selectedSignageListId'
    ],

    inject: [
        'windowWidth'
    ],

    computed: {
        signageTierLimit(): number {
            return tierState.orgTier.restrictionSignageItems;
        },
        
        projectedNewCount(): number {
            const orderedSignageItemsLength = this.orderedSignageItems ? this.orderedSignageItems.length : 0;
            return this.selectedSignages.length + orderedSignageItemsLength;
        },

        canAddSignageItems(): boolean {
            return tierState.canAddSignageItems;
        },

        tierLimitReachedMessage() {
            let newSignageMessage = '';
            let addMessage = '';
            
            if(this.isStorageFull || !this.canAddSignageItems || this.projectedNewCount > this.signageTierLimit) {
                let message = 'storage space nor signage items';

                if(this.isStorageFull && this.canAddSignageItems) {
                    message = 'storage space'
                }

                if((!this.canAddSignageItems || this.projectedNewCount > this.signageTierLimit)  && !this.isStorageFull) {
                    message = 'signage items'
                }

                newSignageMessage = `You do not have any ${message} remaining. `;
                addMessage = 'add more signage items'
            }

            let unlockMessage = ''
            if(!this.hasListManagement || !this.hasScheduling || !tierState.orgTier.sigangeSchedulingEnabled || !tierState.orgTier.sigangeListManagementEnabled || !tierState.orgTier.signageMassMediaUploadEnabled) {
                if(addMessage.length > 0) {
                    unlockMessage = ' or unlock more features'
                }
                else {
                    unlockMessage = ' unlock more features';
                }
            }

            return `${newSignageMessage}Upgrade your Ditto account to ${addMessage}${unlockMessage}.`
        },

       dates: {
            get() {
                return [this.signageList.startDate, this.signageList.endDate]
            },
            // setter
            set(newValue: any) {
                // Note: we are using destructuring assignment syntax here.
                this.signageList.startDate = newValue[0]
                this.signageList.endDate = newValue[1]
            }
        },

        startTime: {
            get() {
                return {
                    hours: this.signageList.startTime ? this.signageList.startTime.split(':')[0] : 0,
                    minutes: this.signageList.startTime ? this.signageList.startTime.split(':')[1] : 0,
                    seconds: 0
                }

            },
            // setter
            set(newValue: any) {
                this.signageList.startTime = new Date(`2022/1/1 ${newValue.hours}:${newValue.minutes}:${newValue.seconds}`).toTimeString().split(' ')[0] as any

            }
        },

        endTime: {
            get() {
                return {
                    hours: this.signageList.endTime ? this.signageList.endTime.split(':')[0] : 0,
                    minutes: this.signageList.endTime ? this.signageList.endTime.split(':')[1] : 0,
                    seconds: 0
                }

            },
            // setter
            set(newValue: any) {
                this.signageList.endTime = new Date(`2022/1/1 ${newValue.hours}:${newValue.minutes}:${newValue.seconds}`).toTimeString().split(' ')[0] as any

            }
        },

        assetQuotaPercentage(): any {
            if(!organizationState.digitalSignageStorageQuota.usedQuota || !organizationState.digitalSignageStorageQuota.totalQuota) return 0;
            return (organizationState.digitalSignageStorageQuota.usedQuota/organizationState.digitalSignageStorageQuota.totalQuota)*100;
        },

        assetCapacityGb() {
            return (organizationState.digitalSignageStorageQuota.totalQuota / 1024 / 1024 / 1024);
        },

        usedStorageToSize() {
            const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
            let bytes = organizationState.digitalSignageStorageQuota.usedQuota;

            if (bytes === 0) return '0'
            const i = Math.floor(Math.log(bytes) / Math.log(1024));
            if (i === 0) return `${bytes} ${sizes[i]})`
            return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`
        },

        isRoomRestricted(): boolean {
            if(!roleState.isSystemRole && roleState.userRole && !roleState.userRole.roomIds.includes('*')) {
                return true;
            }
            
            return false;
        },

        selectedSignagesIdMap() {
            return this.selectedSignages.map((signage: any) => signage.id);
        },
        
        showHideText() {
            return (this as any).signageListToShowHide && (this as any).signageListToShowHide.hideListFromDisplay ? 'Show' : 'Hide'
        },

        orderedSignageItems: {
            get:function() {
                return this.mutatableSignageItemsStateObject.signage;
            },

            set: function(newVal: any) {
                this.mutatableSignageItemsStateObject.signage = newVal;
                this.mutatableSignageItemsStateObject.signage.forEach((item: any, i: any) => {
                    item.order = i+1;
                });
            }
        },

        durations: {
            get() {
                let durations = {} as any;
                (this as any).orderedSignageItems.forEach((item: ISignageItem)=> {
                    const id = item && item.id ? item.id.toString() : null as any;
                    if(id && item.durationToDisplay) {
                        let sec_num = parseInt(item.durationToDisplay, 10);
                        let hours   = Math.floor(sec_num / 3600) as any;
                        let minutes = Math.floor((sec_num - (hours * 3600)) / 60) as any;
                        let seconds = sec_num - (hours * 3600) - (minutes * 60) as any;

                        if (hours   < 10) { hours   = "0" + hours; }
                        if (minutes < 10) { minutes = "0" + minutes; }
                        if (seconds < 10) { seconds = "0" + seconds; }

                        const date = new Date(2021, 1, 1, hours, minutes, seconds);
                        durations[id] = {
                            hours: date.getHours(),
                            minutes: date.getMinutes(),
                            seconds: date.getSeconds()
                        }
                    }
                });
                return durations;
            },

            set(newValue: any) {
                console.log("New dutations value: ", newValue);
            } 
        },
        
        scheduledStartTimes: {
            get() {
                let res = {} as any;
                (this as any).orderedSignageItems.forEach((item: ISignageItem)=> {
                    const id = item && item.id ? item.id.toString() : null as any;
                    if(id) {
                        res[id] = {
                            hours: moment(`2022/1/1 ${item.startTime}`).format('HH'),
                            minutes:  moment(`2022/1/1 ${item.startTime}`).format('mm')
                        }
                    }
                });
                return res;
            },
            set(value: any) {
                console.log('Inside computed setter: ', value);
            }
        },

        scheduledEndTimes: {
            get:function() {
                let res = {} as any;
                (this as any).orderedSignageItems.forEach((item: ISignageItem)=> {
                    const id = item && item.id ? item.id.toString() : null as any;
                    if(id) {
                        res[id] = {
                            hours: moment(`2022/1/1 ${item.endTime}`).format('HH'),
                            minutes:  moment(`2022/1/1 ${item.endTime}`).format('mm')
                        }
                    }
                });
                return res;
            },

            set: function(newVal: any) {
                console.log('Inside computed setter: ', newVal);
            }
        },

        isStorageFull: function() {
            if(organizationState.digitalSignageStorageQuota && organizationState.digitalSignageStorageQuota.usedQuota >= organizationState.digitalSignageStorageQuota.totalQuota) {
                return true;
            }
            return false;
        },

        isOverUploadLimit(): boolean {
            return this.uploadFiles.length > this.uploadLimit;
        },

        isSelectedHidden(): boolean {
            const hidden = this.selectedSignages.filter(x => x.hideFromDisplay)
            if(this.selectedSignages.length > 0 && hidden.length == this.selectedSignages.length) {
                return true;
            }
            return false;
        },

        hideButtonText() {
            if(this.isSelectedHidden == true) { return 'Show'; }
            return 'Hide';
        },

        isMassSelect() {
            if(this.selectedSignages.length > 0) {return true}
            return false;
        },

        allowMassDuplicate(): boolean {
            const loading = this.selectedSignages.filter(x => x.finalSignageAsset.remoteUploadPending == true);
            if(loading.length > 0) {
                return true;
            }
            return false;
        },

        isIndeterminate(): boolean {
            if(this.selectedSignages.length > 0 && this.selectedSignages.length < this.orderedSignageItems.length) {
                return true;
            }
            return false;
        },

        selectAll(): boolean {
            if(this.selectedSignages.length > 0 && this.selectedSignages.length == this.orderedSignageItems.length) {
                return true;
            }
            return false;
        },

        signageMessageUpdates(): IMessageSignageUpdate[] {
            if(this.selectedSignageListId) {
                return signageItemState.messageSignageUpdateBySignageListId(this.selectedSignageListId);    
            }
            return [] as IMessageSignageUpdate[];
        },

        signageListMessageUpdates(): IMessageSignageListUpdate {
            if(this.selectedSignageListId) {
                return signageListState.messageSignageListUpdate(this.selectedSignageListId);
            }

            return {} as IMessageSignageListUpdate;
        },

        hideShowListButtonText(): string {
            return this.signageList.hideListFromDisplay ? 'Show List' : 'Hide List'
        },

        listConflict(): boolean {
            const conflict = signageListState.hasSchedultConflict(this.selectedSignageListId);
            return conflict;
        },

        isMassMediaUploadAllowed(): boolean {
            return tierState.orgTier.signageMassMediaUploadEnabled;
        },
                
        showAllTemplates(): boolean {
            if(tierState.orgTier.name.toLocaleLowerCase() == 'elite' || tierState.orgTier.name.toLocaleLowerCase() == 'trial') {
                return true;
            }

            return false;
        },

        showPreview(): boolean {
            return this.uploadFiles.length > 0;
        },

        massUploadText(): string {
            const errors = this.uploadFiles.filter((x: IFileDisplay) => x.uploadFailed || !x.isValid);
            
            if(this.uploadFiles.length > 0 && errors.length == this.uploadFiles.length) {
                return `Try Upload Again (${this.uploadFiles.length})`;
            }
            return `Upload Items (${this.uploadFiles.length})`;
        },                                      
        
        showTierCallout(): boolean {
            if (!this.canAddSignageItems || this.isStorageFull || !tierState.orgTier.sigangeSchedulingEnabled || !tierState.orgTier.sigangeListManagementEnabled || !tierState.orgTier.signageMassMediaUploadEnabled) {
                return true;
            }

            return false;
        },

        upgradeText(): string {
            return 'Upgrade your Ditto account to unlock this feature.';
        },

        isImport(): boolean {
            if(this.signageList.listType === 'googleSlides') {
                return true;
            }

            return false;
        },

        importErrorMessage (): string {
            if(this.signageList.importError) {
                return this.signageList.importError.message ? this.signageList.importError.message : 'Error generating signage items.';
            }

            return '';
        },

        importDurationTooltipText(): string {
            return 'Duration for Google Slides is set at the list level.';
        },

        massDurationTooltipText(): string {
            if(this.isImport) {
                return this.importDurationTooltipText;
            }

            return this.upgradeText;
        },

        importGenerating(): boolean {
            if(!this.isImport) { return false; }

            if(this.orderedSignageItems && this.orderedSignageItems.length > 0) {
                return this.orderedSignageItems.some(x => !x.finalSignageAsset && !x.finalAssetGenerationError);
            }
           else if (!this.signageList.importError && this.orderedSignageItems && this.orderedSignageItems.length == 0){
                return true;
           }
           else {
            return false;
           }
        },

        importErrors(): boolean {
            if(!this.isImport) { return false; }

            if(this.orderedSignageItems && this.orderedSignageItems.length > 0) {
                return this.orderedSignageItems.some(x => x.finalAssetGenerationError);
            }
           else {
                return false;
           }
        },

        importDuration: {
            get:function() {
                return this.formatDuration(this.signageList.durationToDisplay ? this.signageList.durationToDisplay : 5);
            },

            set: function(newVal: any) {
                const newSeconds = (newVal['hours'] * 60 * 60) + (newVal['minutes'] * 60) + (newVal['seconds']);
                this.signageList.durationToDisplay = newSeconds;
                if(this.signageList.importUrl) {
                    const newMs = newSeconds * 1000;
                    const url = new URL(this.signageList.importUrl);
                    url.searchParams.set('delayms', newMs.toString());
                    this.signageList.importUrl = url.href;
                }
            }   
        },

        listTypeDisplay(): string {
            if(this.signageList.listType && this.signageList.listType === 'googleSlides') {
                return 'Google Slides';
            }
            return 'Standard';
        }
    },

    async created () {
        if(this.$route.hash === '#signage') {
            this.activeTab = 'signage';
        }

        signageListState = getModule(SignageListState);
        signageItemState = getModule(SignageState);
        organizationState = getModule(OrganizationState);
        roleState = getModule(RoleState);
        roomState = getModule(RoomState);
        userState = getModule(UserState);
        tierState = getModule(TierState);

        this.updateQuota();

        this.isSignageListItemsLoading = true;

        await Promise.all([
            signageListState.fetchSignageLists(),
            roleState.fetchUserRole(),
            roomState.fetchRoomsDDL(true),
            signageListState.fetchScheduleConflicts(),
            tierState.fetchAddSignageItemCheck(),
            tierState.fetchTier(),
            userState.fetchMe(null)
        ]);
         
        if(this.selectedSignageListId) {
           await this.loadSignageForList();
        }

        if( userState.me) {
            this.organization = userState.me.organization;
        }

        this.hasListManagement = tierState.orgTier.sigangeListManagementEnabled;
        this.hasScheduling = tierState.orgTier.sigangeSchedulingEnabled;
        this.allRooms = roomState.nonLegacyRooms;
        this.isSignageListItemsLoading = false;
    },

    watch: {
        async selectedSignageListId(): Promise<void> {
            if(this.selectedSignageListId) {
                this.loadSignageForList();
                await signageListState.fetchSignageListEventsById(this.selectedSignageListId);
            }
        },

        'signageList.name': {
            handler(){
                this.changeDetection();
            },
            deep: true
        },

        'signageList.durationToDisplay': {
            async handler(){
                this.changeDetection();
            },
            deep: true
        },

        'signageList.importUrl': {
            async handler(){
                await this.checkPresentationLink();
                this.changeDetection();
            },
            deep: true,
            immediate: true 
        },

        'signageList.hideListFromDisplay': {
            handler(){
                this.changeDetection();
            },
            deep: true
        },

        'signageList.scheduledTimeSet': {
            handler(){
                this.changeDetection();
            },
            deep: true
        },

        'signageList.isTimeEnabled': {
            handler(){
                this.changeDetection();
            },
            deep: true
        },

        'signageList.isDateEnabled': {
            handler(){
                this.changeDetection();
            },
            deep: true
        },

        'signageList.isDayEnabled': {
            handler(){
                this.changeDetection();
            },
            deep: true
        },

        'signageList.startTime': {
            handler(){
                this.changeDetection();
            },
            deep: true
        },

        'signageList.endTime': {
            handler(){
                this.changeDetection();
            },
            deep: true
        },

        'signageList.startDate': {
            handler(){
                this.changeDetection();
            },
            deep: true
        },

        'signageList.endDate': {
            handler(){
                this.changeDetection();
            },
            deep: true
        },

        'signageList.activeDaysOfWeek': {
            handler(){
                this.changeDetection();
            },
            deep: true
        },

        'orderedSignageItems': {
            handler(){
                this.changeDetection();
            },
            deep: true
        },

        'signageMessageUpdates': {
            handler(newMessages, oldMessages){
                if(JSON.stringify(newMessages) !== JSON.stringify(oldMessages)) {
                    this.updateSignagesFromMessage();
                }
            },
            deep: true
        },

        'signageListMessageUpdates': {
            handler(newMessages, oldMessages){
                if(JSON.stringify(newMessages) !== JSON.stringify(oldMessages)) {
                    this.updateSignageListsFromMessage();
                }
            },
            deep: true
        }
    }
});
