
import { defineComponent } from "vue";
import { getModule } from "vuex-module-decorators";
import { ElMessage } from 'element-plus'

import {mdiFullscreen, mdiChevronRight, mdiDotsVertical, mdiPencil, mdiDelete,
mdiDragHorizontalVariant, mdiMagnify, mdiFormatListText, mdiCrosshairsGps, 
mdiInformationVariant, mdiVideoImage, mdiBookOpen, mdiSpotlight, mdiMap, mdiWeb,
mdiViewCarousel, mdiViewSplitVertical, mdiTextBoxPlus, mdiStarBox, mdiFaceMask,
mdiHandWash, mdiAccountGroup, mdiSocialDistance6Feet, mdiOpenInNew } from '@mdi/js';

// Layout Editors --
import DetailedInformationalLayoutEditor from '../signage-layout-editors/DetailedInformationalLayoutEditor.vue';
import DirectoryLayoutEditor from '../signage-layout-editors/DirectoryLayoutEditor.vue';
import FeatureLayoutEditor from '../signage-layout-editors/FeatureLayoutEditor.vue';
import FeatureTwoLayoutEditor from '../signage-layout-editors/FeatureTwoLayoutEditor.vue';
import InformationalLayoutEditor from '../signage-layout-editors/InformationalLayoutEditor.vue';
import LargeHeadlineInformationalLayoutEditor from '../signage-layout-editors/LargeHeadlineInformationalLayoutEditor.vue';
import MediaLayoutEditor from '../signage-layout-editors/MediaLayoutEditor.vue';
import MultiItemMenuLayoutEditor from '../signage-layout-editors/MultiItemMenuLayoutEditor.vue';
import SingleItemMenuLayoutEditor from '../signage-layout-editors/SingleItemMenuLayoutEditor.vue';
import SpotlightLayoutEditor from '../signage-layout-editors/SpotlightLayoutEditor.vue';
import StaticWebUrlLayoutEditor from '../signage-layout-editors/StaticWebUrlLayoutEditor.vue';
import WayfindingLayoutEditor from '../signage-layout-editors/WayfindingLayoutEditor.vue';
import WeatherThreeDayLayoutEditor from '../signage-layout-editors/WeatherThreeDayLayoutEditor.vue';
import WeatherTodayLayoutEditor from '../signage-layout-editors/WeatherTodayLayoutEditor.vue';
import CanvaLayoutEditor from '../signage-layout-editors/CanvaLayoutEditor.vue';
import TwitterLayoutEditor from '../signage-layout-editors/TwitterLayoutEditor.vue';
import GoogleSlidesImportLayoutEditor from '../signage-layout-editors/GoogleSlidesImportLayoutEditor.vue';
import MediaQuadrantsLayoutEditor from '../signage-layout-editors/MediaQuadrantsLayoutEditor.vue';

// Covid Layout Editors --
import CovidMaskLayoutEditor from '../signage-layout-editors/covid/CovidMaskLayoutEditor.vue';
import CovidOccupancyLayoutEditor from '../signage-layout-editors/covid/CovidOccupancyLayoutEditor.vue';
import CovidOccupancyTwoLayoutEditor from '../signage-layout-editors/covid/CovidOccupancyTwoLayoutEditor.vue';
import CovidSafetyLayoutEditor from '../signage-layout-editors/covid/CovidSafetyLayoutEditor.vue';

// Components ---
import Icon from '../Icon.vue';
import DraggableModal from '../DraggableModal.vue';
import Lightbox from '../Lightbox.vue';
import PageDirtyService from '../../services/PageDirtyService';

// Interaces ---
// State ---
import { IMessageSignageUpdate, ISignageItem } from "../../store/interfaces/ISignage";
import SignageState, { IInitializeSignageItemPayload, ISignageState } from "../../store/modules/signage";
import SignagePreviewService from '../../services/SignagePreviewService';
import OrganizationState, { IOrganizationState } from "../../store/modules/organizations";
import UserState, { IUserState } from '../../store/modules/users';
import TierState, { ITierState } from '../../store/modules/tier';

let signageItemState = {} as ISignageState;
let organizationState = {} as IOrganizationState;
let userState = {} as IUserState;
let tierState = {} as ITierState;

export default defineComponent({
  name: 'SignageDetailsComponent',
  data: ()=>({
    signageItem: {} as ISignageItem,
    signageItemOriginal: {} as ISignageItem,
    initSignageItem: {} as ISignageItem,
    layoutDataEdit: {} as any,
    layoutDataOriginal: {} as any,
    selectedSignageItemPreview: null as any,
    signageItemPreviewDebounce: null as any,
    organization: null as any,

    // Boolean Flags
    // ==============
    isEditingItemName: false as boolean,
    signageItemLoading: true as boolean,
    isUpdateSignageItemLoading: false as boolean,
    isLoadingUpdatedPreview: true as boolean,
    isLightboxVisible: false as boolean,
    isPopoutPreviewVisible: false as boolean,

    // Icons
    // ======
    mdiFormatListText,
    mdiDragHorizontalVariant,
    mdiCrosshairsGps,
    mdiInformationVariant,
    mdiVideoImage,
    mdiViewCarousel,
    mdiBookOpen,
    mdiSpotlight,
    mdiSocialDistance6Feet,
    mdiFaceMask,
    mdiStarBox,
    mdiTextBoxPlus,
    mdiAccountGroup,
    mdiViewSplitVertical,
    mdiMap,
    mdiWeb,
    mdiHandWash,
    mdiDotsVertical,
    mdiDelete,
    mdiPencil,
    mdiMagnify,
    mdiChevronRight,
    mdiOpenInNew,
    mdiFullscreen,
  }),

  components: {
    DraggableModal,
    Lightbox,
    Icon,

    // Layout Editors
    // ================
    detailedinformationalLayoutEditor: DetailedInformationalLayoutEditor,
    directoryLayoutEditor: DirectoryLayoutEditor,
    featureLayoutEditor: FeatureLayoutEditor,
    featuretwoLayoutEditor: FeatureTwoLayoutEditor,
    informationalLayoutEditor: InformationalLayoutEditor,
    largeheadlineinformationalLayoutEditor: LargeHeadlineInformationalLayoutEditor,
    mediaLayoutEditor: MediaLayoutEditor,
    multiitemmenuLayoutEditor: MultiItemMenuLayoutEditor,
    singleitemmenuLayoutEditor: SingleItemMenuLayoutEditor,
    spotlightLayoutEditor: SpotlightLayoutEditor,
    staticweburlLayoutEditor: StaticWebUrlLayoutEditor,
    wayfindingLayoutEditor: WayfindingLayoutEditor,
    weatherthreedayLayoutEditor: WeatherThreeDayLayoutEditor,
    weathertodayLayoutEditor: WeatherTodayLayoutEditor,
    mediaquadrantsLayoutEditor: MediaQuadrantsLayoutEditor,


    // Integration Layout Editors
    // ===========================
    canvaLayoutEditor: CanvaLayoutEditor,
    twitterLayoutEditor: TwitterLayoutEditor,
    googleslidesimportLayoutEditor: GoogleSlidesImportLayoutEditor,
    
    // COVID Layout Editors
    // ======================
    covidmaskLayoutEditor: CovidMaskLayoutEditor,
    'covid-occupancyLayoutEditor': CovidOccupancyLayoutEditor,
    covidoccupancytwoLayoutEditor: CovidOccupancyTwoLayoutEditor,
    'covid-safetyLayoutEditor': CovidSafetyLayoutEditor
  },

  methods: {
    async loadSignage() {
      this.signageItemLoading = true;

      const layoutType = this.$route.params.layouttype as string;
      const listId = this.$route.params.listid as string;
      const signageId = this.$route.params.itemid as string;

      if(!listId) {
        this.$router.push({ path: '/signage' });
        return;
      }

      await signageItemState.fetchSignageForList({ signageListId: listId, forceReload: false });
      
      if(this.isCreatingNewSignageItem) {
        if(!layoutType) {
          this.$router.push({ path: `/signage/${listId}` });
          return;
        } 
        const initPayload: IInitializeSignageItemPayload = {
          signageListId: listId,
          layoutType: layoutType
        }
          
        const initResponse = await signageItemState.initializeSignageItem(initPayload);
        
        if(!initResponse.success) {
          ElMessage({
            message: `Signage item initialization failed`,
            type: 'error',
          });
            
          this.$router.push({ path: `/signage/${listId}` })
        }

        this.signageItem = initResponse.data as ISignageItem;
        this.initSignageItem = {...initResponse.data as ISignageItem};
        this.layoutDataEdit = this.signageItem.layoutData;
        this.layoutDataOriginal = this.signageItem.layoutData;
        this.signageItemLoading = false;
        if(!this.shouldUpdatePreview) {
          this.isLoadingUpdatedPreview = false;
        }
        return;
      }
      else {
        if(!signageId) {
            this.$router.push({ path: `/signage/${listId}` })
            return;
        }
          
        // load the signage item based off the URL
        this.signageItem = await signageItemState.signageItem(signageId);
        if(!this.signageItem) {
          ElMessage({
            message: `Signage item failed to load`,
            type: 'error',
          });

          this.$router.push({ path: `/signage/${listId}` })
          return;
        }
        this.layoutDataEdit = this.signageItem.layoutData;
        this.layoutDataOriginal = this.signageItem.layoutData;
        this.signageItemLoading = false;
          
        if(this.shouldUpdatePreview) {
          try {
              this.isLoadingUpdatedPreview = true;
              const updatedPreview = (await SignagePreviewService.updatePreview(this.signageItem as ISignageItem));
              this.isLoadingUpdatedPreview = false;
              this.$nextTick(() => {
                  this.selectedSignageItemPreview = updatedPreview ? updatedPreview.preview : null;
              });
          } catch(err) {
              this.isLoadingUpdatedPreview = true;
          }
        }
        else {
          this.isLoadingUpdatedPreview = false;
        }
      }
      if(signageId) {
        this.signageItemOriginal = await signageItemState.signageItem(signageId); 
      }
    },

    async changeDetection() {
      if(this.isImport && this.isCreatingNewSignageItem) {
        PageDirtyService.markPageClean();
        return;
      }
      let originalSignage;
      if(this.signageItem && this.signageItem.id) {
        originalSignage = await signageItemState.signageItem(this.signageItem.id) as any;
      }
       
      //we don't want to monitor the fields that the event emitter is updating that the user cannot update
      const origSignage = {...originalSignage} as any;
      delete origSignage.finalSignageAsset;
      delete origSignage.finalAssetGenerationError;
      delete origSignage.wipThumb;

      const editedSignage = {...this.signageItem} as any;
      delete editedSignage.finalSignageAsset;
      delete editedSignage.finalAssetGenerationError;
      delete editedSignage.wipThumb;
      
      PageDirtyService.monitorObjectSet([
            {defaultObject: origSignage, mutatedObject: editedSignage},
      ]);

      const queue = signageItemState.getFileUploadQueue;
      if(queue.length) {
        PageDirtyService.markPageDirty(); 
      }
    },

    async onSubmit(): Promise<void> {
      if(this.isLoadingUpdatedPreview || this.isUpdateSignageItemLoading) return;
      this.isUpdateSignageItemLoading = true;

      const payload = {
        signageListId: this.$route.params.listid as string,
        signageItem: this.signageItem,
        shouldUpdatePreview: this.shouldUpdatePreview
      } as any;
      let metaSaveResults = null as any;
      let saveResponse = null as any;
      
      if(!this.isCreatingNewSignageItem) {
        metaSaveResults = await signageItemState.updateSignageItemMeta(this.signageItem);
        if(!metaSaveResults.success) {
          ElMessage({
            message: 'Error saving signage item',
            type: 'error',
          });
          this.isUpdateSignageItemLoading = false;
          return;
        }
      }

      if(this.isCreatingNewSignageItem || (JSON.stringify(this.signageItem.layoutData) != JSON.stringify(this.layoutDataOriginal) || this.fileUploadQueue.length || this.signageItem.refreshedLayout || this.signageItem.layoutType == 'mediaquadrants')) {
        saveResponse = await signageItemState.saveSignageItem(payload) as any;
        if(!saveResponse.success || !saveResponse.data.id) {
          ElMessage({
            message: 'Error saving signage item',
            type: 'error'
          });
          this.isUpdateSignageItemLoading = false;
          return;
        }
      
        if(this.isCreatingNewSignageItem) {
          const newItem = await signageItemState.signageItem(saveResponse.data.id);
          if(newItem) {
            PageDirtyService.markPageClean();
            await tierState.fetchAddSignageItemCheck(true);
            this.$router.push({ path: `/signage/${payload.signageListId}/${newItem.id}` });
          }
          else {
            ElMessage({
              message: `Signage item failed to load`,
              type: 'error',
            });
            this.$router.push({ path: `/signage/${payload.signageListId}` })
            this.isUpdateSignageItemLoading = false;
            return;
          }
              
        }

        this.loadSignage();

        if((this.signageItem.layoutType === 'media' || this.signageItem.layoutType === 'canva' || this.signageItem.layoutType === 'googleslidesimport') && saveResponse.data && saveResponse.data.finalSignageAsset){
          this.signageItem.finalSignageAsset = saveResponse.data.finalSignageAsset;
        }
        
        this.signageItemLoading = false;
      }

      this.selectedSignageItemPreview = this.signageItem.wipThumb;
      await organizationState.fetchSignageAssetQuota();
      this.isUpdateSignageItemLoading = false;
      ElMessage({
        message: 'Signage item saved',
        type: 'success',
      });

      PageDirtyService.markPageClean();
      signageItemState.clearFileQueue();
    },

    toggleEditListNameInput(){ 
      this.isEditingItemName = true;
      this.$nextTick(()=> {
        (this as any).$refs['editItemNameInput'].focus();
      });
    },

    updatePlaySound(playSound: boolean) {
      this.signageItem.playSound = playSound;
      this.changeDetection();
    },

    updateLayoutData(componentUpdate: any) {
      this.signageItem.layoutData = componentUpdate.updatedLayoutData;
      this.changeDetection();

      if(componentUpdate.shouldUpdatePreview) {
        if(this.signageItemPreviewDebounce) clearTimeout(this.signageItemPreviewDebounce);
  
        // Only update the preview after 1500ms
        this.signageItemPreviewDebounce = setTimeout(()=> {
          this.isLoadingUpdatedPreview = true;
          SignagePreviewService.updatePreview(this.signageItem).then((resp: any)=> {
            if(resp) {
              this.isLoadingUpdatedPreview = false;
              this.selectedSignageItemPreview = resp.preview;
            }
          });
        }, 1500);
      }
    },

    updateSignageFromMessage() {
      if(this.signageMessageUpdate) {
        this.signageItem.finalSignageAsset = this.signageMessageUpdate.finalSignageAsset;
        this.signageItem.finalAssetGenerationError = this.signageMessageUpdate.finalAssetGenerationError;
        this.signageItem.wipThumb = this.signageMessageUpdate.wipThumb;
      }
    },

    // difference(obj1: any, obj2: any): boolean {
    //   let keyFound = false;
    //   if(obj1 && obj2) {
    //   Object.keys(obj1).forEach(key => {
    //       if(obj1[key] !== obj2[key]){
    //         console.log('key difference', key);
    //         return keyFound = true;
    //       }
    //   });
    //   return keyFound;
    // }
  },

  props: ['isCreatingNewSignageItem'],

  computed: {
    signageItemEditorComponent(): string {
      // Returns a string that will map the selected signage item to
      // the corresponding editor component. Ex: media -> MediaLayoutEditor
      // This is used for the dynamic <component /> element 
      const layoutType = this.signageItem ? this.signageItem.layoutType : '';
      return layoutType + "LayoutEditor";
    },

    fileUploadQueue(): any[] {
        const queue = signageItemState.getFileUploadQueue;
        if(queue.length) {
          this.changeDetection();
        }
        return queue;
    },

    signageItemPreviewImage(): string | null {
      if((this.isImport && !this.signageItem.finalSignageAsset) || (this.signageItem.refreshedLayout && this.signageItem.finalAssetGenerationError || (this.signageItem.finalSignageAsset && this.signageItem.finalSignageAsset.remoteUploadPending)) ){
        return 'images/signage-layout-images/staticweburlloading.png';
      }
      if (this.signageItem.layoutType !== 'media' && this.signageItem.layoutType !== 'canva'  && this.signageItem.layoutType !== 'googleslidesimport' && this.selectedSignageItemPreview) {
        return `data:image/png;base64,${ this.selectedSignageItemPreview }`;
      }
      if (this.signageItem.layoutType == 'media' || this.signageItem.layoutType == 'canva' || this.signageItem.layoutType == 'googleslidesimport') {
        if(this.signageItem.finalSignageAsset && this.signageItem.finalSignageAsset.assetType == 'video') {
          return this.signageItem.finalSignageAsset.thumbUrl; 
        }
        if(this.signageItem.signageAssets && this.signageItem.signageAssets.length) {
          const assetFiles = this.signageItem.signageAssets.flatMap((x: any) => x.assetFiles);
          if(assetFiles.length) {   
            return assetFiles[0].url;
          }
        }
      }
      if(this.signageItem.finalSignageAsset && this.signageItem.finalSignageAsset.thumbUrl && !this.signageItem.finalSignageAsset.thumbUrl.includes('undefined')) {
        return this.signageItem.finalSignageAsset.thumbUrl;      
      }
      if (this.signageItem && this.signageItem.wipThumb) {
        return `data:image/png;base64,${this.signageItem.wipThumb}`;
      } else {
        if(this.signageItem.layoutType) {
          return `images/signage-layout-images/${this.signageItem.layoutType}.png`;
        }
        else {
          return null;
        }
      }
    },
      
    hasError(): boolean {
      if((this.signageItem as any).finalAssetGenerationError) {
        return true;
      }
      else {
        return false;
      }
    },

    isSavingRequired(): boolean {
      if (this.signageItem.refreshedLayout && JSON.stringify(this.signageItem.layoutData) != JSON.stringify(this.layoutDataOriginal)){
      return true;
      }
      return false;
    },

    isValidMediaLayout: function() {
      if (this.signageItem.layoutType === 'media') {
        if((this.isCreatingNewSignageItem && this.fileUploadQueue.length == 0) ||
          (this.fileUploadQueue.length == 0 && 
          this.signageItem.playSound === this.signageItemOriginal.playSound &&
          this.signageItem.name === this.signageItemOriginal.name)) {
            return false;
          }
      }
      return true;
    },

    showPreview(): boolean {
      if((this.signageItem.layoutType == "media" || this.signageItem.layoutType == "canva" || this.signageItem.layoutType == "googleslidesimport" )&& this.isCreatingNewSignageItem) {
        return false;
      }
      return true;
    },

    shouldUpdatePreview(): boolean {
      if(this.signageItem && JSON.stringify(this.signageItem) !== '{}' && !this.signageItem.refreshedLayout && this.signageItem.layoutType !== "media" && this.signageItem.layoutType !== "mediaquadrants" && this.signageItem.layoutType !== "canva" && this.signageItem.layoutType !== "googleslidesimport") {
        return true;
      }
      return false;
    },

    finalAssetGenerationErrorMessage(): string {
      if((this.signageItem.layoutType === "staticweburl" || this.signageItem.layoutType === "twitter" ||  this.signageItem.layoutType === "googleslidesimport") && this.signageItem.finalAssetGenerationError)
      {
        return this.signageItem.finalAssetGenerationError.message ? this.signageItem.finalAssetGenerationError.message : 'Error loading URL';
      }
      return '';
    },

    signageMessageUpdate() {  
      if(this.signageItem.id) {
        return signageItemState.messageSignageUpdateBySignageId(this.signageItem.id);
      }
      return {} as IMessageSignageUpdate;
    },

    isLayoutDisbabled() {
      if(this.signageItem.layoutType == 'canva' && !tierState.orgTier.signageCanvaEnabled) {
          return true;
      }
      if(this.signageItem.layoutType == 'twitter' && !tierState.orgTier.signageTwitterEnabled) {
          return true;
      }
      return false;
    },

    featureWarningText(): string {
      const name = this.signageItem.layoutType.charAt(0).toUpperCase() + this.signageItem.layoutType.slice(1);
      return `${name} is not enabled for your organization.`;
    },

    isImport(): boolean {
        if(this.signageItem.layoutType && (this.signageItem.layoutType === 'googleslidesimport' || this.signageItem.layoutType === 'canva')) {
          return true;
        }

        return false;
    },
  },
    inject: [
        'windowWidth'
    ],


  async created() {
    signageItemState = getModule(SignageState);
    organizationState = getModule(OrganizationState);
    userState = getModule(UserState);
    tierState = getModule(TierState)
    
    signageItemState.clearFileQueue();

    await userState.fetchMe();
    await tierState.fetchTier();

    if( userState.me) {
        this.organization = userState.me.organization;
    }

    await this.loadSignage();
  },

  watch: {
    'signageItem.name': {
      handler(){
        this.changeDetection();
      }
    },

    'signageMessageUpdate': {
      handler(newMessage, oldMessage){
        if(JSON.stringify(newMessage) !== JSON.stringify(oldMessage)) {
          this.updateSignageFromMessage();
        }  
      },
      deep: true
    }
  }
});
